import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const settings = require('./i18nSettings');

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: settings.defaultLanguages,
    whitelist: settings.supportedLanguages,
    debug: false,
    keySeparator: settings.keySeparator,
    nsSeparator: settings.namespaceSeparator,
    returnEmptyString: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      loadPath: (langs, namespaces) =>
        namespaces.includes('instance') ? `instance/locales/{{lng}}.json` : `locales/{{lng}}/{{ns}}.json`,
    },
  });

export default i18n;
