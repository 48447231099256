import React from 'react';
import LanguageSelector from 'components/LanguageSelector';
import { Trans } from 'react-i18next';

const BrandNav = () => (
  <React.Fragment>
    <a className="nav-link" href="mailto:service@neurocar.pl">
      <Trans i18nKey="components.BrandNav.item.contact" />
    </a>
    <LanguageSelector />
  </React.Fragment>
);

export default BrandNav;
