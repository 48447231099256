import React from 'react';

export const AUTH_STATUS = {
  NOT_VERIFIED: 0,
  VERIFIED: 1,
  BAD_CREDENTIALS: -1,
};

export const AuthContext = React.createContext({
  account: {},
  session: {},
  permissions: [],
  login: () => {},
  logout: () => {},
});
