import React from 'react';
import PropTypes from 'prop-types';

function Header({ title, nav }) {
  return (
    <header className="masthead mb-auto">
      <div className="inner">
        <h3 className="masthead-brand">{title}</h3>
        <nav className="nav nav-masthead justify-content-center">{nav}</nav>
      </div>
    </header>
  );
}

Header.propTypes = {
  title: PropTypes.node,
  nav: PropTypes.node,
};

export default Header;
