import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const StatusTitle = ({ code, description }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      {t('components.StatusTitle.http-error')} <span className="neuro-cyan">{code}</span> · {description}
    </React.Fragment>
  );
};

StatusTitle.propTypes = {
  code: PropTypes.number,
  description: PropTypes.string,
};

export default StatusTitle;
