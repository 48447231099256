import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function LoginFailure({ onRetry }) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="alert alert-danger text-left">
        <strong>{t('components.LoginFailure.login-failed')}</strong>
        <br />
        <span>{t('components.LoginFailure.invalid-username-or-password')}</span>
      </div>
      <p>
        <button type="button" className="btn btn-outline-light btn-block" onClick={onRetry}>
          {t('components.LoginFailure.button.retry')}
        </button>
      </p>
    </React.Fragment>
  );
}

LoginFailure.propTypes = {
  onRetry: PropTypes.func,
};

export default LoginFailure;
