import React, { useContext, useEffect, useRef } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import DOMPurify from 'dompurify';
import CoveredFrame from 'components/CoveredFrame';
import BrandNav from 'components/BrandNav';
import LoginForm from 'components/LoginForm';
import LoginSuccess from 'components/LoginSuccess';
import LoginFailure from 'components/LoginFailure';
import { AuthContext, AUTH_STATUS } from 'containers/AuthProvider/authContext';
import { useTranslation, Trans } from 'react-i18next';

function SignInPage({ location }) {
  const didMountRef = useRef(false);

  const { t } = useTranslation(['translation', 'instance']);
  const authContext = useContext(AuthContext);
  const { handlers, ...auth } = authContext;
  const { session, status } = auth;
  const { sid } = session || {};

  document.title = `${t('instance:title')} - ${t('containers.SignInPage.title')}`;
  const variants = [
    {
      test: (id, s) => !id && s === AUTH_STATUS.NOT_VERIFIED,
      content: () => (
        <p className="text-center text-wait">
          <Trans i18nKey="containers.SignInPage.auth-in-progress" />
        </p>
      ),
    },
    {
      test: (id, s) => !id && s === AUTH_STATUS.VERIFIED,
      content: () => <LoginForm onSubmit={handlers.login} />,
    },
    {
      test: (id, s) => !id && s === AUTH_STATUS.BAD_CREDENTIALS,
      content: () => <LoginFailure onRetry={handlers.clear} />,
    },
    {
      test: (id, s) => id && s === AUTH_STATUS.VERIFIED,
      content: () => <LoginSuccess auth={auth} onLogout={handlers.logout} />,
    },
  ];

  const variant = variants.find(v => v.test(sid, status));
  const content = (variant && variant.content()) || <LoginFailure onRetry={handlers.retry} />;

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const targetUrl =
      urlParams.get('t') ||
      (process.env.NODE_ENV && process.env.NODE_ENV.toLowerCase() !== 'development' && '/') ||
      undefined;
    if (didMountRef.current) {
      // redirect only if it is not first render -> when SignInPage is mounted
      if (sid && status === AUTH_STATUS.VERIFIED && targetUrl) {
        window.location = targetUrl;
      }
    } else {
      didMountRef.current = true;
    }
  }, [sid, status]); // eslint-disable-line

  const headerContent = <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('instance:header')) }} />;
  const footerContent = <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('instance:footer')) }} />;

  return (
    <CoveredFrame header={headerContent} nav={<BrandNav />} footer={footerContent} logo="instance/images/logo.png">
      {content}
    </CoveredFrame>
  );
}

SignInPage.propTypes = {
  location: ReactRouterPropTypes.location,
};

export default SignInPage;
