import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import CoveredFrame from 'components/CoveredFrame';
import StatusTitle from 'components/StatusTitle';
import BrandNav from 'components/BrandNav';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';

const Status403Page = ({ location }) => {
  const urlParams = new URLSearchParams(location.search);
  const targetUrl =
    urlParams.get('t') ||
    (process.env.NODE_ENV && process.env.NODE_ENV.toLowerCase() !== 'development' && '/') ||
    '/webauth';
  const referrerUrl = urlParams.get('r') || '';

  const { t } = useTranslation(['translation', 'instance']);
  document.title = `${t('instance:title')} - 403`;
  const footerContent = <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('instance:footer')) }} />;
  return (
    <CoveredFrame
      header={<StatusTitle code={403} description={t('containers.Status403Page.forbidden')} />}
      footer={footerContent}
      nav={<BrandNav />}
      logo="instance/images/logo.png"
    >
      <p>{t('containers.Status403Page.no-permissions')}</p>
      {referrerUrl ? (
        <p>
          <strong className="font-italic">{referrerUrl}</strong>
        </p>
      ) : null}
      <p>
        <a className="btn btn-outline-light" href="mailto:service@neurocar.pl">
          {t('containers.Status403Page.button.contact')}
        </a>
        <a href={targetUrl || '/'} className="btn btn-light ml-3">
          {t('containers.Status403Page.button.home')}
        </a>
      </p>
    </CoveredFrame>
  );
};

Status403Page.propTypes = {
  location: ReactRouterPropTypes.location,
};

export default Status403Page;
