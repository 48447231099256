import React from 'react';
import PropTypes from 'prop-types';
import useBodyClass from 'hooks/useBodyClass';
import Header from 'components/Header';
import Cover from 'components/Cover';
import Footer from 'components/Footer';

const CoveredFrame = ({ header, nav, logo, footer, children }) => {
  const { addBodyClass } = useBodyClass();
  addBodyClass(['text-center']);

  return (
    <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
      <Header title={header} nav={nav} />
      <Cover logo={logo}>{children}</Cover>
      <Footer>{footer}</Footer>
    </div>
  );
};

CoveredFrame.propTypes = {
  header: PropTypes.node,
  nav: PropTypes.node,
  logo: PropTypes.string,
  footer: PropTypes.node,
  children: PropTypes.node,
};

export default CoveredFrame;
