import React, { useState, useContext, useEffect } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import DOMPurify from 'dompurify';
import { Redirect } from 'react-router-dom';
import CoveredFrame from 'components/CoveredFrame';
import BrandNav from 'components/BrandNav';
import { AuthContext, AUTH_STATUS } from 'containers/AuthProvider/authContext';
import { useTranslation } from 'react-i18next';

function SignOutPage({ location }) {
  const { t } = useTranslation(['translation', 'instance']);
  const [isSignedOut, setIsSignedOut] = useState(false);

  const authContext = useContext(AuthContext);
  const { handlers, session, status } = authContext;
  const { sid } = session || {};

  const urlParams = new URLSearchParams(location.search);
  const targetUrl = urlParams.get('t');

  document.title = `${t('instance:title')} - ${t('containers.SignOutPage.title')}`;

  useEffect(() => {
    async function logout() {
      try {
        await handlers.logout();
      } catch (err) {
        console.log('Failed to logout');
      }
    }

    if (status === AUTH_STATUS.VERIFIED) {
      if (sid) {
        logout();
      }
      if (targetUrl) {
        window.location.replace(targetUrl);
      } else {
        setIsSignedOut(true);
      }
    }
  }, [handlers, session, sid, status, targetUrl]);

  const headerContent = <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('instance:header')) }} />;
  const footerContent = <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('instance:footer')) }} />;

  return isSignedOut ? (
    <Redirect to="sign-in" />
  ) : (
    <CoveredFrame header={headerContent} nav={<BrandNav />} footer={footerContent} logo="instance/images/logo.png">
      {t('containers.SignOutPage.signing-out')}
    </CoveredFrame>
  );
}

SignOutPage.propTypes = {
  location: ReactRouterPropTypes.location,
};

export default SignOutPage;
