import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AuthProvider from 'containers/AuthProvider/AuthProvider';
import SignInPage from 'containers/SignInPage';
import SignOutPage from 'containers/SignOutPage';
import Status401Page from 'containers/Status401Page';
import Status403Page from 'containers/Status403Page';
import Status404Page from 'containers/Status404Page';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'ubuntu-fontface/ubuntu.min.css';
import 'assets/css/styles.css';

function App() {
  return (
    <AuthProvider>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route path="/sign-in" component={SignInPage} />
          <Route path="/sign-out" component={SignOutPage} />
          <Route path="/401" component={Status401Page} />
          <Route path="/403" component={Status403Page} />
          <Route path="/404" component={Status404Page} />
          <Redirect from="/" to="/sign-in" />
        </Switch>
      </Suspense>
    </AuthProvider>
  );
}

export default App;
