import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useTranslation } from 'react-i18next';

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const handleSelectLanguage = lang => {
    i18n.changeLanguage(lang);
  };

  const currentLanguage = i18n.language || '';
  const availableLanguages = (i18n.options.whitelist || []).filter(l => l !== 'cimode' && l !== 'dev');
  const languageItems = availableLanguages.map(l => (
    <Dropdown.Item key={`lang_${l}`} eventKey={l} active={l === currentLanguage} className="text-uppercase">
      {l || ''}
    </Dropdown.Item>
  ));
  return (
    <DropdownButton
      id="languageSelector"
      title={currentLanguage}
      size="sm"
      onSelect={handleSelectLanguage}
      alignRight
      variant="outline-light text-uppercase"
      className="ml-3"
    >
      {languageItems}
    </DropdownButton>
  );
};

LanguageSelector.propTypes = {};

export default LanguageSelector;
