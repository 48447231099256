import React from 'react';
import { Link } from 'react-router-dom';
import CoveredFrame from 'components/CoveredFrame';
import StatusTitle from 'components/StatusTitle';
import BrandNav from 'components/BrandNav';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';

const Status401Page = () => {
  const { t } = useTranslation(['translation', 'instance']);
  document.title = `${t('instance:title')} - 401`;
  const footerContent = <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('instance:footer')) }} />;
  return (
    <CoveredFrame
      header={<StatusTitle code={401} description={t('containers.Status401Page.unauthenticated')} />}
      nav={<BrandNav />}
      footer={footerContent}
      logo="instance/images/logo.png"
    >
      <p>{t('containers.Status401Page.please-sign-in')}</p>
      <p>
        <Link to="sign-in" className="btn btn-outline-light">
          {t('containers.Status401Page.button.sign-in')}
        </Link>
      </p>
    </CoveredFrame>
  );
};

export default Status401Page;
