import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function Cover({ logo, children }) {
  const { t } = useTranslation(['instance']);
  return (
    <main role="main" className="inner cover mx-auto">
      <div className="mx-auto">
        <div className="text-center">
          <img src={logo} height="140" alt={t('instance:sign-in.logo-alt')} />
        </div>
        {children}
      </div>
    </main>
  );
}

Cover.propTypes = {
  logo: PropTypes.string,
  children: PropTypes.node,
};

export default Cover;
