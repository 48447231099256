import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

function LoginSuccess({ auth, onLogout }) {
  const { account } = auth;
  const { t } = useTranslation();
  const username = account.name;
  return (
    <div className="mx-auto">
      <p className="mb-0">
        <Trans i18nKey="components.LoginSuccess.signed-in-as">
          You are signed in as <strong>{{ username }}</strong>.
        </Trans>
      </p>
      <p>{t('components.LoginSuccess.message')}</p>
      <p>
        <button type="button" className="btn btn-outline-light" onClick={onLogout}>
          {t('components.LoginSuccess.button.sign-out')}
        </button>
      </p>
    </div>
  );
}

LoginSuccess.propTypes = {
  onLogout: PropTypes.func,
  auth: PropTypes.shape({
    session: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    permissions: PropTypes.array.isRequired,
  }),
};

export default LoginSuccess;
