import React, { useRef, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';

function LoginForm({ onSubmit, badCredentials = false }) {
  const userNameRef = useRef();
  const passwordRef = useRef();
  const { t } = useTranslation(['translation', 'instance']);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  useLayoutEffect(() => {
    if (userNameRef && userNameRef.current) {
      userNameRef.current.focus();
    }
  }, []);

  function handleUsernameChange(e) {
    e.preventDefault();
    setUsername(e.target.value);
  }

  function handlePasswordChange(e) {
    e.preventDefault();
    setPassword(e.target.value);
  }

  function handleLogin(e) {
    e.preventDefault();
    setIsSubmitting(true);
    if (onSubmit) {
      onSubmit(username, password);
    }
  }

  const message = DOMPurify.sanitize(t('instance:sign-in.message'));

  return (
    <form className="form-signin mx-auto" onSubmit={handleLogin}>
      <div className="text-center mb-4">
        <p dangerouslySetInnerHTML={{ __html: message }} />
      </div>
      {badCredentials ? (
        <div className="alert alert-danger text-left">
          <strong>{t('components.LoginForm.login-failed')}</strong>
          <br />
          <span>{t('components.LoginForm.invalid-username-or-password')}</span>
        </div>
      ) : null}
      <div className="form-label-group mb-4">
        <input
          type="text"
          id="inputUsername"
          autoComplete="username"
          className="form-control"
          placeholder={t('components.LoginForm.placeholder.username')}
          required
          disabled={isSubmitting}
          value={username}
          onChange={handleUsernameChange}
          ref={userNameRef}
        />
      </div>
      <div className="form-label-group mb-4">
        <input
          type="password"
          id="inputPassword"
          autoComplete="current-password"
          className="form-control"
          placeholder={t('components.LoginForm.placeholder.password')}
          required
          disabled={isSubmitting}
          value={password}
          onChange={handlePasswordChange}
          ref={passwordRef}
        />
      </div>
      <button className="btn btn-outline-light btn-block" type="submit" disabled={isSubmitting}>
        {t('components.LoginForm.button.sign-in')}
      </button>
    </form>
  );
}

LoginForm.propTypes = {
  badCredentials: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default LoginForm;
