import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import CoveredFrame from 'components/CoveredFrame';
import StatusTitle from 'components/StatusTitle';
import BrandNav from 'components/BrandNav';
import DOMPurify from 'dompurify';
import { useTranslation, Trans } from 'react-i18next';

const Status404Page = ({ location }) => {
  const { t } = useTranslation(['translation', 'instance']);
  document.title = `${t('instance:title')} - 403`;
  const footerContent = <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('instance:footer')) }} />;

  const urlParams = new URLSearchParams(location.search);
  const targetUrl =
    urlParams.get('t') ||
    (process.env.NODE_ENV && process.env.NODE_ENV.toLowerCase() !== 'development' && '/') ||
    '/webauth';
  const referrerUrl = urlParams.get('r') || '';

  return (
    <CoveredFrame
      header={<StatusTitle code={404} description={t('containers.Status404Page.notfound')} />}
      footer={footerContent}
      nav={<BrandNav />}
      logo="instance/images/logo.png"
    >
      <Trans i18nKey="containers.Status404Page.message">
        <p className="text-break">
          Requested page <strong className="font-italic">{{ referrerUrl }}</strong>
          <br />
          does not exist.
        </p>
      </Trans>
      <p>
        <a className="btn btn-outline-light" href="mailto:service@neurocar.pl">
          {t('containers.Status404Page.button.contact')}
        </a>
        <a href={targetUrl || '/'} className="btn btn-light ml-3">
          {t('containers.Status404Page.button.home')}
        </a>
      </p>
    </CoveredFrame>
  );
};

Status404Page.propTypes = {
  location: ReactRouterPropTypes.location,
};

export default Status404Page;
