import React from 'react';
import PropTypes from 'prop-types';

function Footer({ children }) {
  return (
    <footer className="mastfoot mt-auto">
      <div className="inner">{children}</div>
    </footer>
  );
}

Footer.propTypes = {
  children: PropTypes.node,
};

export default Footer;
