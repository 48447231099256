import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { createAccessAPI } from '@nrs/js-neuro-api';
import { API_URL } from 'appconstants';
import { AuthContext, AUTH_STATUS } from './authContext';

const defaultState = {
  session: {},
  account: {},
  permissions: [],
  status: AUTH_STATUS.NOT_VERIFIED,
};

const AuthProvider = ({ children }) => {
  const [state, setState] = useState(defaultState);

  const accessAPI = useMemo(() => createAccessAPI(API_URL), []);

  const loadSessionInfo = useCallback(async () => {
    try {
      const sessionResp = await accessAPI.sessions.get();
      const accountResp = await accessAPI.accounts.get(sessionResp.account);
      setState(prevState => ({
        ...prevState,
        session: sessionResp,
        account: accountResp,
        status: AUTH_STATUS.VERIFIED,
      }));
    } catch (error) {
      setState({ ...defaultState, status: AUTH_STATUS.VERIFIED });
      console.log('No active session has been found\n', error); // eslint-disable-line no-console
    }
  }, [accessAPI.accounts, accessAPI.sessions]);

  const login = async (username, password) => {
    try {
      await accessAPI.sessions.login(username, password);
      await loadSessionInfo();
    } catch (error) {
      setState({
        ...defaultState,
        status: AUTH_STATUS.BAD_CREDENTIALS,
      });
      console.log('Failed to login\n', error); // eslint-disable-line no-console
    }
  };

  const logout = async () => {
    try {
      await accessAPI.sessions.logout();
      setState(defaultState);
      await loadSessionInfo();
    } catch (error) {
      console.log('Failed to logout\n', error); // eslint-disable-line no-console
    }
  };

  const reset = () => {
    setState(defaultState);
  };

  const clear = () => {
    setState({ ...defaultState, status: AUTH_STATUS.VERIFIED });
  };

  useEffect(() => {
    loadSessionInfo();
  }, [loadSessionInfo]);

  const authContext = { handlers: { login, logout, reset, clear }, ...state };

  return <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
